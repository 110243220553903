import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import "./components/global";
import "./utils";
import "./libs";

import * as filters from './libs/filters'

Object.keys(filters).forEach(key=>{
  Vue.filter(key,filters[key])//插入过滤器名和对应方法
})

// Midone Theme
import "./assets/sass/app2.scss";
import './icons' // icon

// touch
import touch from 'vue-directive-touch';
Vue.use(touch);

// 复制
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});
