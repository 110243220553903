const state = () => {

  let menus = [];
  if (process.env.NODE_ENV !== "production" ) {
    menus = [{
      icon: 'HomeIcon',
      pageName: 'base-icon',
      title: '图标',
      url:'/base/icon'
    }]
  }
  menus.push(...[
    // {
    //   icon: 'HomeIcon',
    //   pageName: 'base-setting-index',
    //   title: '基本设置',
    //   url:'/base-setting/index'
    // },
    {
      icon: 'BoxIcon',
      pageName: 'withdraw-index',
      title: '提现列表',
      url:'/withdraw/index'
    },
    {
      icon: 'InboxIcon',
      pageName: 'order-list-index',
      title: '订单列表',
      url:'/order-list/index'
    },
  ])
  return {
    menu: menus
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
