import Vue from 'vue';
import VueRouter from 'vue-router';
import request from '../utils/request'

import Cookies from "js-cookie";

Vue.use(VueRouter);

const routes = [
	{
		name: 'withdraw-index',
		path: '/',
		component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
		children: [
			{
				path: '/',
				name: 'withdraw-index',
				meta: { title: '提现列表' },
				component: () => import('@/views/withdraw/index')
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		meta: { title: '用户登录' },
		component: () => import('@/views/Login')
	},
	{
		name: 'base',
		path: '/base',
		component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
		children: [
			{
				path: '/base/icon',
				name: 'base-icon',
				meta: { title: '图标' },
				component: () => import('@/views/Icon')
			}
		]
	},
	// {
	// 	name: 'base-setting',
	// 	path: '/base-setting',
	// 	component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
	// 	children: [
	// 		{
	// 			path: '/base-setting/index',
	// 			name: 'base-setting-index',
	// 			meta: { title: '基本设置' },
	// 			component: () => import('@/views/base-setting/index')
	// 		}
	// 	]
	// },
	{
		name: 'withdraw',
		path: '/withdraw',
		component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
		children: [
			{
				path: '/withdraw/withdraw',
				name: 'withdraw-index',
				meta: { title: '提现列表' },
				component: () => import('@/views/withdraw/index')
			}
		]
	},
	{
		name: 'order-list',
		path: '/order-list',
		component: () => import(/* webpackChunkName: 'main' */ '@/layouts/SideMenu'),
		children: [
			{
				path: '/order-list/index',
				name: 'order-list-index',
				meta: { title: '订单列表' },
				component: () => import('@/views/order-list/index')
			}
		]
	},
	{
		path: '/auth',
		name: 'auth-page',
		meta: { title: '授权' },
		component: () => import('@/views/auth/AuthPage.vue')
	},
	{
		path: '/404',
		name: 'error-page',
		meta: { title: '管理中心' },
		component: () => import('@/views/ErrorPage')
	},
	{
		path: '/401',
		name: 'unauthorized-page',
		meta: { title: '权限不足' },
		component: () => import('@/views/auth/AuthErrorPage')
	},
	{
		path: "*",
		redirect: '/404',
		hidden: true,
	}
];






const router = new VueRouter({
	mode: 'hash',  //history
	// mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	}
});

router.beforeEach((to, from, next) => {


	document.title = to.meta.title ? to.meta.title + ' - 行业首家淘客,商城数字营销&管理系统' : '行业首家淘客,商城数字营销&管理系统'




	// if (to.path != '/' && to.path != '/register' && to.path != '/isRegister' && to.path != '/m_index') {
	// 	request({
	// 		url: '/api/sign/status',
	// 		method: 'POST'
	// 	}).then(res => {
	// 		res.data.fin_invalid = Number(res.data.fin_invalid)
	// 		res.data.fin_valid = Number(res.data.fin_valid)
	// 		Cookies.set('userInfo', res.data)
	// 		Cookies.set('discount', res.data.discount)
	// 		// 用户登录未失效， 直接跳转到会员中心页面
	// 		if (to.path === '/') {
	// 			next('/Dashboard')
	// 		}
	// 	}).catch(err => {
	// 		next('/login')
	// 	})
	// }
	next()
})




export default router;
