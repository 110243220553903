import axios from 'axios'

import { Message } from 'element-ui'

import router from '@/router'

// const token = false



// create an axios instance
const service = axios.create({
  timeout: 15000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  response => {
    console.log(response, 'response')
  },
  error => {
    // Do something with request error
    console.error("出错啦",error) // for debug
  }
)

// response interceptor
service.interceptors.response.use(response => {
  if (response.status != 200) {
    Message.error(`${response.statusText} :${response.status}`);
  }
  return response.data
},
  error => {
    if (error.response.status === 401) {
        router.push('/401')
    }
    else if (error.response.status !== 200 ) {
      Message.error(error.response.data.msg)
    }
    return Promise.reject( error.response )
  }
)



export default function request(param) {
  return new Promise((resolve, reject) => {
    service({
      url: param.url,
      method: param.method || 'GET',
      params: param.query,
      data: param.data
    }).then((result) => {
      const { code , data, msg} = result
      if (code === 1) {
        resolve(result.data)
      } else {
        Message.error(msg)
        reject(msg)
      }
    }).catch((err) => {
      console.error(err)
      reject(err)
    });
  })
}





// export default service
